import { HomeModel } from "./home.model";
import { KeysModel } from "./keys.model";
import { MenuModel } from "./menu.model";
import { ThemeModel } from "./theme.model"
import { UrlsModel } from "./urls.model";

export class SettingsModel {

  public theme: ThemeModel = null;
  public home: HomeModel = null;
  public menu: MenuModel = null;
  public keys: KeysModel = null;
  public urls: UrlsModel = null;

  constructor(model?: Partial<SettingsModel>) {
    this.theme = new ThemeModel(model?.theme);
    this.home = new HomeModel(model?.home);
    this.menu = new MenuModel(model?.menu);
    this.keys = new KeysModel(model?.keys);
    this.urls = new UrlsModel(model?.urls);
  }
}
