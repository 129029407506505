/**
 * An enum that describes all present types of custom html inputs
 */
export enum InputEnum {
  //When adding a new input, update:
  // * rule/action.enum.ts to list available actions that can be taken upon it
  // * any.input.ts to have it be actually rendered
  // * the below arrays to classify it, makes it easier to work with
  Text = 't',
  TextArea = 'ta',
  //SAVED: we don't need this at present
  // MultiText = 'mt',
  Select ='s',
  MultiSelect = 'ms',
  Checkbox = 'c',
  ButtonToggle = 'bt',
  Date = 'd',
  DateTime = 'dt',
  DateTimeRange = 'dtr',
  Time = 'ti',
  Year = 'y',
  File = 'f',
  Image = 'i',
  MiniImage = 'mi',
  Map = 'm',
  SearchAgent = 'sa',
  SearchFirmAgent = 'sfa',
  SearchBuyerAgent = 'sba',
  SearchBuyerFirmAgent = 'sbfa',
  SearchOffice = 'so',
  SearchBuyerOffice = 'sbo',
  SearchOfficeInfo = 'soi',
  SearchPropertyComplex = 'spc',
  SearchPropertyFloorPlan = 'spf',
  SearchPropertyUnit = 'spu',
}

// The below arrays are currently used as easy methods of classifying the various inputs into different categories.
// If adding an input, please classify it for simplicity.
export const SearchInputArray = [
  InputEnum.SearchAgent,
  InputEnum.SearchFirmAgent,
  InputEnum.SearchBuyerAgent,
  InputEnum.SearchBuyerFirmAgent,
  InputEnum.SearchOffice,
  InputEnum.SearchBuyerOffice,
  InputEnum.SearchOfficeInfo,
  InputEnum.SearchPropertyComplex,
  InputEnum.SearchPropertyFloorPlan,
  InputEnum.SearchPropertyUnit
];
export const FileInputArray = [
  InputEnum.File,
  InputEnum.Image,
  InputEnum.MiniImage
];
export const TextInputArray = [
  InputEnum.Text,
  InputEnum.TextArea,
  // InputEnum.MultiText
];
export const MultiInputArray = [
  // InputEnum.MultiText,
  InputEnum.MultiSelect
];
export const SelectInputArray = [
  InputEnum.Select,
  InputEnum.ButtonToggle,
  InputEnum.MultiSelect,
];
export const BooleanInputArray = [
  InputEnum.Checkbox
];
export const DateInputArray = [
  InputEnum.Date,
  InputEnum.DateTime,
  InputEnum.DateTimeRange
];
export const TimeInputArray = [
  InputEnum.Time
];
const exclude: InputEnum[] = [
  ...SearchInputArray
];

/**
 * An array of possible Input elements (InputEnum).
 * Search inputs are excluded here because they're 'special'
 */
export const InputArray = Object.values(InputEnum).filter(e => !exclude.includes(e));

/**
 * Provides a legible name and a description for all Inputs (InputEnum)
 */
export const InputDetails = {
  [InputEnum.Text]: {
    name: 'Text Box',
    desc: 'A simple text input'
  },
  [InputEnum.TextArea]: {
    name: 'Text Area',
    desc: 'A text input for writing larger amounts of text'
  },
  // [InputEnum.MultiText]: {
  //   name: 'Multi Text',
  //   desc: 'Allows multiple values in one box'
  // },
  [InputEnum.Select]: {
    name: 'Select Box',
    desc: 'Select a single option from a list'
  },
  [InputEnum.MultiSelect]: {
    name: 'Multi Select',
    desc: 'Select multiple options from a list'
  },
  [InputEnum.Checkbox]: {
    name: 'Checkbox',
    desc: 'Allows toggling between on and off'
  },
  [InputEnum.ButtonToggle]: {
    name: 'Button Toggle',
    desc: 'Select a single option from a small list'
  },
  [InputEnum.Date]: {
    name: 'Date',
    desc: 'Provides a calendar to pick a single date'
  },
  [InputEnum.DateTime]: {
    name: 'DateTime',
    desc: 'Pick a single date along with a time'
  },
  [InputEnum.DateTimeRange]: {
    name: 'DateTime Range',
    desc: 'Pick a single date along with a start/end time'
  },
  [InputEnum.Time]: {
    name: 'Time',
    desc: 'Pick a single time'
  },
  [InputEnum.Year]: {
    name: 'Year',
    desc: 'Pick a year'
  },
  [InputEnum.File]: {
    name: 'File',
    desc: 'Upload one or more files'
  },
  [InputEnum.Image]: {
    name: 'Image',
    desc: 'Upload one or more images'
  },
  [InputEnum.MiniImage]: {
    name: 'Mini Image',
    desc: 'Upload one or more images'
  },
  [InputEnum.Map]: {
    name: 'Map',
    desc: 'A map of the google variety'
  },
  [InputEnum.SearchAgent]: {
    name: 'Agent',
    desc: 'Searches for agents'
  },
  [InputEnum.SearchFirmAgent]: {
    name: 'Firm Agent',
    desc: 'Searches for agents within the entire firm'
  },
  [InputEnum.SearchBuyerAgent]: {
    name: 'Buyer Agent',
    desc: 'Searches for buyer agents'
  },
  [InputEnum.SearchBuyerFirmAgent]: {
    name: 'Buyer Firm Agent',
    desc: 'Searches for buyer agents within the entire firm'
  },
  [InputEnum.SearchOffice]: {
    name: 'Office',
    desc: 'Searches for offices'
  },
  [InputEnum.SearchBuyerOffice]: {
    name: 'Buyer Office',
    desc: 'Searches for buyer offices'
  },
  [InputEnum.SearchOfficeInfo]: {
    name: 'Office Info',
    desc: 'Displays additional office information'
  },
  [InputEnum.SearchPropertyComplex]: {
    name: 'Property Complex',
    desc: 'Searches for property complexes'
  },
  [InputEnum.SearchPropertyFloorPlan]: {
    name: 'Property Floor Plan',
    desc: 'Searches for property floorplans'
  },
  [InputEnum.SearchPropertyUnit]: {
    name: 'Property Unit',
    desc: 'Searches for property units'
  }
}

/** Checks if an input is a type of text input */
export function isTextInput(input: InputEnum): boolean { return TextInputArray.includes(input) }
/** Checks if an input is a type of multiple input */
export function isMultiInput(input: InputEnum): boolean { return MultiInputArray.includes(input) }
/** Checks if an input is a type of select input */
export function isSelectInput(input: InputEnum): boolean { return SelectInputArray.includes(input) }
/** Checks if an input is a type of boolean input */
export function isBooleanInput(input: InputEnum): boolean { return BooleanInputArray.includes(input) }
/** Checks if an input is a type of date input */
export function isDateInput(input: InputEnum): boolean { return DateInputArray.includes(input) }
/** Checks if an input is a type of time input */
export function isTimeInput(input: InputEnum): boolean { return TimeInputArray.includes(input) }
/** Checks if an input is a type of search input */
export function isSearchInput(input: InputEnum): boolean { return SearchInputArray.includes(input) }
/** Checks if an input is a type of file input */
export function isFileInput(input: InputEnum): boolean { return FileInputArray.includes(input) }
