import { ActionEnum } from "src/app/_enum/rule/action.enum";
import { TriggerEnum } from "src/app/_enum/rule/trigger.enum";
import { arraysEqual, isArray } from "../../_helper/util.helper";
import { BaseModel } from "../base.model";
import { KeyValueInterface } from "../form/interface/key-value.interface";
import { PropertyModel } from "../property/property.model";
import { UserModel } from "../member/user.model";

/**
 * A base Rule model that contains the most essential rule data to be saved.
 */
export class RuleModel extends BaseModel {

  public from: string | string[] = null;
  public to: string = null;
  public trigger: TriggerEnum = null;
  public tValue: any = null;
  public action: ActionEnum = null;
  public aValue: any = null;
  public constraints: { field: string, value: string[], values?: KeyValueInterface[] }[] = [];
  public roles: string[] = [];

  constructor(model?: Partial<RuleModel>) {
    super();
    this.overwrite(model);
  }

  /**
   * Returns a boolean indicating whether a rule is triggered by a specific mapping (input).
   * Necessary because it can potentially be from multiple inputs at once.
   */
  public isFrom(mapping: string | string[]): boolean {
    if (isArray(mapping)) return isArray(this.from) ? arraysEqual(<string[]>this.from, <string[]>mapping) : mapping.includes(<string>this.from);
    return isArray(this.from) ? this.from.includes(<string>mapping) : this.from === mapping;
  }

  /**
   * Indicates whther the rule is triggered by multiple inputs (true) or a single input (false)
   */
  public isFromMultiple(): boolean {
    return isArray(this.from);
  }

  /**
   * Checks the constraints on a rule (if applicable) against the data on a listing.
   * If the constraints are found in the data, the rule can potentially trigger.
   */
  public constraintsValid(listing: PropertyModel): boolean {
    for (let constraint of this.constraints) {
      if (constraint.value.length && !constraint.value.includes(listing[constraint.field])) {
        return false;
      }
    }
    return true;
  }

  public rolesValid(user: UserModel): boolean {
    if (!this.roles.length) return true;
    for (let role of this.roles) {
      if (user?.memberRoles?.find(r => r.roleName === role)) {
        return true;
      }
    }
    return false;
  }
}
